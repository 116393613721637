<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Revenue Coach",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Revenue Coach",
      items: [
        {
          text: "Revenue coach",
        },
        {
          text: "Revenue Coach",
          active: true,
        },
      ],
      users: [],
      coachs: [],
      coach: null,
      date: null,
      loadingUsers: false,
      savingCoach: false,
      totalRevenuePrograms: 0,
      totalPrograms: 0,
      totalRevenueTickets: 0,
      totalMessages: 0,
      totalWords: 0
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      //return this.userList.length;
      return 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        this.getCoachs();
      }
    });
  },
  methods: {
    async checkRevenue() {
      this.totalRevenuePrograms = 0;
      this.totalRevenueTickets = 0;
      this.totalWords = 0;
      this.totalMessages = 0;
      this.totalPrograms = 0;

      var dateSplit = this.date.split("-");
      var month = dateSplit[1] - 1;
      var year = dateSplit[0];
      var FirstDay = new Date(year, month, 1).getTime();
      var LastDay = new Date(year, month + 1, 0, 23, 59, 59).getTime();

      console.log(FirstDay + " | " + LastDay);

      var db = firebase.firestore();
      var referencecoach = await db.collection("users").doc(this.coach);

      //On récupère d'abord les programmes
      await db
        .collection("premiumprograms")
        .where("coach", "==", referencecoach)
        .where("date_created", ">=", FirstDay)
        .where("date_created", "<=", LastDay)
        .get()
        .then((programs) => {
          this.totalPrograms = programs.size
          if(programs.size > 0)
          this.totalRevenuePrograms = programs.size * 3;
        });

      //On récupère d'abord les programmes
      await db
        .collectionGroup("messages")
        .where("author", "==", referencecoach)
        .where("date", ">=", FirstDay)
        .where("date", "<=", LastDay)
        .get()
        .then((messages) => {
          this.totalMessages = messages.size
          messages.forEach((message) => {
            var data = message.data();
            var length = data.message.length;
            this.totalWords += length
            var revenue = length * 0.01 > 1.3 ? 1.3 : length * 0.01;
            this.totalRevenueTickets += revenue;
          });
        }).catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    async getCoachs() {
      var db = firebase.firestore();
      await db
        .collection("users")
        .where("role", "array-contains", "coach")
        .get()
        .then((coachs) => {
          coachs.forEach((coach) => {
            this.coachs.push({
              value: coach.id,
              text: coach.data().first_name + " " + coach.data().last_name,
            });
          });
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h2 class="my-3">Revenue Coach</h2>
            <b-form-select v-model="coach" :options="coachs"></b-form-select>
            <b-form-input
              v-model="date"
              placeholder="Select a month"
              type="date"
            ></b-form-input>
            <b-button variant="outline-primary" @click="checkRevenue()"
              >GO</b-button
            >
            <hr />
            <h5>Programs</h5>
            <p>Total Programs: {{ totalPrograms }}</p>
            <p>Total Revenue Programs: {{ totalRevenuePrograms }}€</p>
            <h5>Tickets</h5>
            <p>Total Messages: {{ totalMessages }}</p>
            <p>Total Words Ticket: {{ totalWords }}</p>
            <p>Total Revenue Ticket: {{ totalRevenueTickets }}€</p>
            <hr/>
            <p><strong>Total : {{ totalRevenuePrograms + totalRevenueTickets }}€</strong></p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
